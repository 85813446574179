<script>
  import { get } from 'vuex-pathify'
  import { getPayPalToken } from '@/helpers/recurly'
  import LogoPayPal from '@/images/logos/LogoPayPal.svg'

  export default {
    components: {
      LogoPayPal,
    },
    data() {
      return {
        errorMessage: null,
      }
    },
    computed: {
      ...get('feature', ['flags']),
      isPayPalEnabled() {
        return this.flags['checkout-paypal'] ?? false
      },
    },
    methods: {
      selectCreditCard() {
        this.$emit('submit', {
          type: 'credit-card',
        })
      },
      async selectPayPal() {
        this.$store.set('general/navigationLoading', true)
        const token = await getPayPalToken()
        if (!token) {
          this.$store.set('general/navigationLoading', false)
          return
        }
        try {
          this.$emit('submit', {
            type: 'paypal',
            token,
          })
        } catch (error) {
          this.errorMessage = error.message
        } finally {
          this.$store.set('general/navigationLoading', false)
        }
      },
    },
  }
</script>

<template>
  <div>
    <BasePanelWarning v-if="errorMessage" class="mb-4">
      {{ errorMessage }}
    </BasePanelWarning>

    <div class="mb-2 flex items-center text-gray-darker text-xs">
      <BaseIcon :size="4" class="mr-1 mt-2px flex-shrink-0">
        <IconLock />
      </BaseIcon>
      <span>Secure Transaction</span>
    </div>

    <BaseStack>
      <BaseButtonSelect @click="selectCreditCard">
        <div class="font-heading font-medium text-2xl">Credit / Debit</div>
        <div class="mt-2 block">
          <BaseCreditCards />
        </div>
      </BaseButtonSelect>
      <BaseButtonSelect v-if="isPayPalEnabled" class="paypal-button" @click="selectPayPal">
        <LogoPayPal class="paypal-logo w-30 h-8" />
      </BaseButtonSelect>
    </BaseStack>
  </div>
</template>

<style lang="postcss" scoped>
  .paypal-button {
    :not(:disabled):active .paypal-logo >>> * {
      fill: white;
    }

    &:disabled .paypal-logo >>> * {
      fill: theme('colors.gray.dark');
    }
  }
</style>
