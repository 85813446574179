<script>
  import { call } from 'vuex-pathify'
  import BillingPaymentMethodForm from '@/components/account/BillingPaymentMethodForm'

  export default {
    components: {
      BillingPaymentMethodForm,
    },
    methods: {
      ...call('account', ['updateBillingInfo']),
      async submit($event) {
        if ($event.token) {
          await this.updateBillingInfo($event.token)
          this.$navigate({ name: 'CheckoutBilling' })
          return
        }
        if ($event.type === 'credit-card') {
          this.$navigate({ name: 'CheckoutBillingCreditCard' })
        }
      },
    },
  }
</script>

<template>
  <section>
    <BaseHeading size="h5" tag="h2">Change payment method</BaseHeading>

    <BaseRichText class="mt-2 mb-4">
      Changing your payment method will update your account’s default payment method.
    </BaseRichText>

    <BillingPaymentMethodForm @submit="submit" />

    <div class="mt-6 -mb-2 flex justify-center">
      <BaseLinkStyled :to="{ name: 'CheckoutBilling' }" color="gray" font-size="xs">
        Cancel
      </BaseLinkStyled>
    </div>
  </section>
</template>
